export const platform = {
  overview: 'platform/overview',
  tenants: 'platform/tenants',
  users: 'platform/users',
  logs: 'platform/loginfo',
  wifi: 'platform/template/type',
} as const;

export const values = Object.values(platform);

export type PlatformPerms = typeof values[number];

export default platform;
