<template>
  <ProConfigProvider :theme="{ token: { colorPrimary: '#2585CD', fontSize: fontSize } }">
    <router-view v-slot="{ Component }" :key="$route.fullPath">
      <component :is="Component" />
    </router-view>
  </ProConfigProvider>
</template>

<script setup lang="ts">
  import { watchEffect } from 'vue';
  import { useRoute } from 'vue-router';
  import { transformI18n } from './hooks/useI18n';
  import { useFontSize } from '@/utils/useFontSize';
  // import { LockScreen } from '@/components/basic/lockscreen';
  // import ProConfigProvider from '@/components/basic/pro-config-provider/index.vue';

  const route = useRoute();

  const { fontSize } = useFontSize();

  watchEffect(() => {
    if (route.meta?.title) {
      // 翻译网页标题
      document.title = transformI18n(route.meta.title);
    }
  });
</script>

<style lang="less">
  body {
    margin: 0;
    padding: 0;
  }
</style>
