import { type RouteRecordRaw } from 'vue-router';
import { defineStore } from 'pinia';
// import { useWsStore } from './ws';
import { store } from '@/store';
import { login } from '@/api/login';
import { ACCESS_TOKEN_KEY, LAST_USER_ROLE } from '@/enums/cacheEnum';
import { Storage } from '@/utils/Storage';
import { logout, getInfo, permmenu } from '@/api/account';
import { generatorDynamicRouter } from '@/router/generator-router';
import { resetRouter } from '@/router';

export enum UserType {
  None,
  Admin,
  Normal,
}

export enum UserRole {
  None,
  Management,
  Tenant,
}

interface UserState {
  token: string;
  name: string;
  avatar: string;
  // like [ 'sys:user:add', 'sys:user:update' ]
  perms: string[];
  menus: RouteRecordRaw[];
  userInfo: Partial<API.AdminUserInfo>;
  userRole: UserRole;
  userType: UserType;
  tenantName: string;
  tenantId: number;
}

export const useUserStore = defineStore({
  id: 'user',
  state: (): UserState => ({
    token: Storage.get(ACCESS_TOKEN_KEY, null),
    name: 'Admin',
    avatar: '',
    perms: [],
    menus: [],
    userInfo: {},
    userRole: UserRole.None,
    userType: UserType.None,
    tenantName: '',
    tenantId: 0,
  }),
  getters: {
    getToken(): string {
      return this.token;
    },
    getAvatar(): string {
      return this.avatar;
    },
    getName(): string {
      return this.name;
    },
    getPerms(): string[] {
      return this.perms;
    },
    getUserType(): UserType {
      return this.userType;
    },
    getUserRole(): UserRole {
      return this.userRole;
    },
  },
  actions: {
    // 清空token及用户信息
    resetToken() {
      this.avatar = this.token = this.name = '';
      this.perms = [];
      this.menus = [];
      this.userInfo = {};
      Storage.clear();
      Storage.set(LAST_USER_ROLE, this.userRole);
    },
    // 登录成功保存token
    setToken(token: string) {
      this.token = token ?? '';
      const ex = 7 * 24 * 60 * 60 * 1000;
      Storage.set(ACCESS_TOKEN_KEY, this.token, ex);
    },
    // 登录
    async login(params: API.LoginParams) {
      try {
        const { data } = await login(params);
        this.setToken(`Bearer ${data.token}`);
        return this.afterLogin();
      } catch (error) {
        return Promise.reject(error);
      }
    },
    // 登录成功之后, 获取用户信息以及生成权限路由
    async afterLogin() {
      try {
        // const wsStore = useWsStore();
        // const [userInfo, { perms, menus }] = await Promise.all([getInfo(), permmenu()]);
        const userInfo = await getInfo();
        const { perms, menus } = await permmenu();
        this.perms = perms;
        this.name = userInfo.username;
        this.userRole = userInfo.role;
        this.userType = userInfo.type;
        // this.avatar = userInfo.headImg;
        this.userInfo = userInfo;
        this.tenantName = userInfo.tenant_name;
        this.tenantId = userInfo.tenant_id;
        // 生成路由
        console.log('UserInfo: ', userInfo);
        console.log('Perms: ', perms);
        console.log('Response menus: ', menus);
        const generatorResult = generatorDynamicRouter(menus);
        this.menus = generatorResult.menus.filter((item) => !item.meta?.hideInMenu);
        // wsStore.initSocket();

        return { menus, perms, userInfo };
      } catch (error) {
        return this.logout();
      }
    },
    // 登出
    async logout() {
      await logout();
      // const wsStore = useWsStore();
      // wsStore.closeSocket();
      this.resetToken();
      resetRouter();
    },
  },
});

// 在组件setup函数外使用
export function useUserStoreWithOut() {
  return useUserStore(store);
}
