export const user = {
  default: 'users',
  myOverview: 'user/overview',
  mySetting: 'user/setting',
  myPassword: 'user/password',
  myVerifyPasswd: 'user/password/verify',
} as const;

export const values = Object.values(user);

export type UserPerms = typeof values[number];

export default user;
