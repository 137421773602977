export const tenant = {
  nodes: 'tenant/nodes',
  overview: 'tenant/overview',
  tags: 'tenant/tags',
  users: 'tenant/users',
  proxy: 'tenant/proxy',
  logs: 'tenant/loginfo',
} as const;

export const values = Object.values(tenant);

export type TenantPerms = typeof values[number];

export default tenant;
