<template>
  <div class="page-container">
    <div class="page-error">
      <h1>{{ $t('error.page404.pageNotFound') }}</h1>
      <h3>{{ $t('error.page404.error404') }}</h3>
      <span>{{ $t('error.page404.content404') }}</span>
      <br />
      <br />
      <RouterLink :to="{ path: '/', replace: true }" class="ant-btn ant-btn-primary">{{
        $t('error.page404.button404')
      }}</RouterLink>
    </div>
    <div id="NotFound"><div ref="animation" style="width: 500px"></div></div>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
  import { useI18n } from 'vue-i18n';
  import lottie from 'lottie-web';
  import errror from '@/assets/404.json';

  export default defineComponent({
    name: 'NotFound',
    setup() {
      const { t } = useI18n();
      return {
        t,
      };
    },
    mounted() {
      this.anim = lottie.loadAnimation({
        container: this.$refs.animation,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: errror, //动画json
      });
    },
  });
</script>

<style lang="less" scoped>
  .page-container {
    display: flex;
    background-color: white;
    justify-content: center;
    align-items: center;
  }

  .page-error {
    align-content: center;
    align-items: center;
    width: 500px;
  }

  .page-error h1 {
    font-weight: 300;
    font-size: 54px;
    text-transform: uppercase;
    line-height: 64px;
    letter-spacing: 0.75px;
  }

  .page-error h3 {
    font-weight: 600;
    font-size: 24px;
    text-transform: uppercase;
    line-height: 1.6;
    letter-spacing: 0.75px;
    margin: 30px;
  }

  .page-error span {
    font-weight: 300;
    font-size: 14px;
    line-height: 2.2;
    letter-spacing: 0.76px;
  }
</style>
