import { ref, watch } from 'vue';

const DEFAULT_FONT_SIZE = 13;
const fontSize = ref<number>(DEFAULT_FONT_SIZE);

const storedFontSize = localStorage.getItem('fontSize');
if (storedFontSize) {
  fontSize.value = parseInt(storedFontSize);
}

watch(fontSize, (newValue) => {
  localStorage.setItem('fontSize', newValue.toString());
});

window.addEventListener('storage', (event) => {
  if (event.key === 'fontSize') {
    fontSize.value = parseInt(event.newValue || DEFAULT_FONT_SIZE.toString());
  }
});

export function useFontSize() {
  return {
    fontSize,
  };
}
