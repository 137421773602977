export default [
  {
    createTime: '2021-09-20 15:09:20',
    updateTime: '2021-09-20 15:09:20',
    id: 1488,
    ip: '127.0.0.1',
    userId: 1,
    params: '{}',
    action: '/admin/account/permmenu',
    method: 'GET',
    status: 200,
    consumeTime: 13,
  },
  {
    createTime: '2021-09-20 15:09:20',
    updateTime: '2021-09-20 15:09:20',
    id: 1487,
    ip: '127.0.0.1',
    userId: 1,
    params: '{}',
    action: '/admin/account/info',
    method: 'GET',
    status: 200,
    consumeTime: 3,
  },
  {
    createTime: '2021-09-20 15:09:20',
    updateTime: '2021-09-20 15:09:20',
    id: 1486,
    ip: '127.0.0.1',
    userId: 1,
    params: '{}',
    action: '/admin/account/permmenu',
    method: 'GET',
    status: 200,
    consumeTime: 16,
  },
  {
    createTime: '2021-09-20 15:09:20',
    updateTime: '2021-09-20 15:09:20',
    id: 1485,
    ip: '127.0.0.1',
    userId: 1,
    params: '{}',
    action: '/admin/account/info',
    method: 'GET',
    status: 200,
    consumeTime: 2,
  },
  {
    createTime: '2021-09-20 15:09:20',
    updateTime: '2021-09-20 15:09:20',
    id: 1484,
    ip: '127.0.0.1',
    userId: 1,
    params: '{}',
    action: '/admin/account/permmenu',
    method: 'GET',
    status: 200,
    consumeTime: 21,
  },
  {
    createTime: '2021-09-20 15:09:20',
    updateTime: '2021-09-20 15:09:20',
    id: 1483,
    ip: '127.0.0.1',
    userId: 1,
    params: '{}',
    action: '/admin/account/info',
    method: 'GET',
    status: 200,
    consumeTime: 7,
  },
  {
    createTime: '2021-09-20 15:09:10',
    updateTime: '2021-09-20 15:09:10',
    id: 1482,
    ip: '127.0.0.1',
    userId: null,
    params: '{}',
    action: '/admin/captcha/img',
    method: 'GET',
    status: 200,
    consumeTime: 7,
  },
  {
    createTime: '2021-09-20 08:13:26',
    updateTime: '2021-09-20 08:13:26',
    id: 1481,
    ip: '127.0.0.1',
    userId: 1,
    params: '{"page":1,"limit":20,"departmentIds":[4]}',
    action: '/admin/sys/user/page',
    method: 'POST',
    status: 200,
    consumeTime: 12,
  },
  {
    createTime: '2021-09-20 08:13:24',
    updateTime: '2021-09-20 08:13:24',
    id: 1480,
    ip: '127.0.0.1',
    userId: 1,
    params: '{"page":1,"limit":20}',
    action: '/admin/sys/user/page',
    method: 'POST',
    status: 200,
    consumeTime: 8,
  },
  {
    createTime: '2021-09-20 08:13:24',
    updateTime: '2021-09-20 08:13:24',
    id: 1479,
    ip: '127.0.0.1',
    userId: 1,
    params: '{}',
    action: '/admin/sys/dept/list',
    method: 'GET',
    status: 200,
    consumeTime: 3,
  },
  {
    createTime: '2021-09-20 08:13:20',
    updateTime: '2021-09-20 08:13:20',
    id: 1478,
    ip: '127.0.0.1',
    userId: 1,
    params: '{}',
    action: '/admin/sys/dept/list',
    method: 'GET',
    status: 200,
    consumeTime: 5,
  },
  {
    createTime: '2021-09-20 08:13:20',
    updateTime: '2021-09-20 08:13:20',
    id: 1477,
    ip: '127.0.0.1',
    userId: 1,
    params: '{}',
    action: '/admin/sys/menu/list',
    method: 'GET',
    status: 200,
    consumeTime: 4,
  },
  {
    createTime: '2021-09-20 08:13:19',
    updateTime: '2021-09-20 08:13:19',
    id: 1476,
    ip: '127.0.0.1',
    userId: 1,
    params: '{}',
    action: '/admin/sys/role/list',
    method: 'GET',
    status: 200,
    consumeTime: 2,
  },
  {
    createTime: '2021-09-20 08:12:25',
    updateTime: '2021-09-20 08:12:25',
    id: 1475,
    ip: '127.0.0.1',
    userId: 1,
    params: '{}',
    action: '/admin/sys/menu/list',
    method: 'GET',
    status: 200,
    consumeTime: 6,
  },
  {
    createTime: '2021-09-20 08:12:25',
    updateTime: '2021-09-20 08:12:25',
    id: 1474,
    ip: '127.0.0.1',
    userId: 1,
    params: '{}',
    action: '/admin/sys/dept/list',
    method: 'GET',
    status: 200,
    consumeTime: 5,
  },
  {
    createTime: '2021-09-20 08:12:23',
    updateTime: '2021-09-20 08:12:23',
    id: 1473,
    ip: '127.0.0.1',
    userId: 1,
    params: '{}',
    action: '/admin/sys/role/list',
    method: 'GET',
    status: 200,
    consumeTime: 2,
  },
  {
    createTime: '2021-09-20 08:12:20',
    updateTime: '2021-09-20 08:12:20',
    id: 1472,
    ip: '127.0.0.1',
    userId: 1,
    params: '{}',
    action: '/admin/sys/menu/list',
    method: 'GET',
    status: 200,
    consumeTime: 4,
  },
  {
    createTime: '2021-09-20 08:12:12',
    updateTime: '2021-09-20 08:12:12',
    id: 1471,
    ip: '127.0.0.1',
    userId: 1,
    params: '{}',
    action: '/admin/account/info',
    method: 'GET',
    status: 200,
    consumeTime: 2,
  },
  {
    createTime: '2021-09-20 08:12:12',
    updateTime: '2021-09-20 08:12:12',
    id: 1470,
    ip: '127.0.0.1',
    userId: 1,
    params: '{}',
    action: '/admin/account/info',
    method: 'GET',
    status: 200,
    consumeTime: 5,
  },
  {
    createTime: '2021-09-20 08:12:12',
    updateTime: '2021-09-20 08:12:12',
    id: 1469,
    ip: '127.0.0.1',
    userId: 1,
    params: '{}',
    action: '/admin/account/permmenu',
    method: 'GET',
    status: 200,
    consumeTime: 6,
  },
  {
    createTime: '2021-09-20 08:12:12',
    updateTime: '2021-09-20 08:12:12',
    id: 1468,
    ip: '127.0.0.1',
    userId: 1,
    params: '{}',
    action: '/admin/account/permmenu',
    method: 'GET',
    status: 200,
    consumeTime: 6,
  },
  {
    createTime: '2021-09-20 08:12:12',
    updateTime: '2021-09-20 08:12:12',
    id: 1467,
    ip: '127.0.0.1',
    userId: 1,
    params: '{}',
    action: '/admin/account/info',
    method: 'GET',
    status: 200,
    consumeTime: 1,
  },
  {
    createTime: '2021-09-20 08:12:12',
    updateTime: '2021-09-20 08:12:12',
    id: 1466,
    ip: '127.0.0.1',
    userId: 1,
    params: '{}',
    action: '/admin/account/permmenu',
    method: 'GET',
    status: 200,
    consumeTime: 7,
  },
  {
    createTime: '2021-09-20 08:12:06',
    updateTime: '2021-09-20 08:12:06',
    id: 1465,
    ip: '127.0.0.1',
    userId: null,
    params: '{}',
    action: '/admin/captcha/img',
    method: 'GET',
    status: 200,
    consumeTime: 4,
  },
  {
    createTime: '2021-09-20 08:12:05',
    updateTime: '2021-09-20 08:12:05',
    id: 1464,
    ip: '127.0.0.1',
    userId: null,
    params: '{}',
    action: '/admin/captcha/img',
    method: 'GET',
    status: 200,
    consumeTime: 3,
  },
  {
    createTime: '2021-09-20 08:12:02',
    updateTime: '2021-09-20 08:12:02',
    id: 1463,
    ip: '127.0.0.1',
    userId: null,
    params: '{}',
    action: '/admin/captcha/img',
    method: 'GET',
    status: 200,
    consumeTime: 4,
  },
  {
    createTime: '2021-09-20 08:11:59',
    updateTime: '2021-09-20 08:11:59',
    id: 1462,
    ip: '127.0.0.1',
    userId: null,
    params: '{}',
    action: '/admin/captcha/img',
    method: 'GET',
    status: 200,
    consumeTime: 4,
  },
  {
    createTime: '2021-09-20 08:11:51',
    updateTime: '2021-09-20 08:11:51',
    id: 1461,
    ip: '127.0.0.1',
    userId: null,
    params: '{}',
    action: '/admin/captcha/img',
    method: 'GET',
    status: 200,
    consumeTime: 5,
  },
  {
    createTime: '2021-09-20 02:45:12',
    updateTime: '2021-09-20 02:45:12',
    id: 1460,
    ip: '127.0.0.1',
    userId: null,
    params: '{}',
    action: '/admin/captcha/img',
    method: 'GET',
    status: 200,
    consumeTime: 7,
  },
  {
    createTime: '2021-09-19 08:13:37',
    updateTime: '2021-09-19 08:13:37',
    id: 1459,
    ip: '127.0.0.1',
    userId: null,
    params: '{}',
    action: '/admin/captcha/img',
    method: 'GET',
    status: 200,
    consumeTime: 5,
  },
  {
    createTime: '2021-09-18 12:33:17',
    updateTime: '2021-09-18 12:33:17',
    id: 1458,
    ip: '127.0.0.1',
    userId: null,
    params: '{}',
    action: '/admin/captcha/img',
    method: 'GET',
    status: 200,
    consumeTime: 9,
  },
  {
    createTime: '2021-09-17 19:48:12',
    updateTime: '2021-09-17 19:48:12',
    id: 1457,
    ip: '127.0.0.1',
    userId: null,
    params: '{}',
    action: '/admin/captcha/img',
    method: 'GET',
    status: 200,
    consumeTime: 6,
  },
  {
    createTime: '2021-09-17 02:26:14',
    updateTime: '2021-09-17 02:26:14',
    id: 1456,
    ip: '127.0.0.1',
    userId: 1,
    params: '{}',
    action: '/admin/sys/menu/list',
    method: 'GET',
    status: 200,
    consumeTime: 4,
  },
  {
    createTime: '2021-09-17 02:26:11',
    updateTime: '2021-09-17 02:26:11',
    id: 1455,
    ip: '127.0.0.1',
    userId: 1,
    params: '{}',
    action: '/admin/sys/role/list',
    method: 'GET',
    status: 200,
    consumeTime: 2,
  },
  {
    createTime: '2021-09-17 02:26:10',
    updateTime: '2021-09-17 02:26:10',
    id: 1454,
    ip: '127.0.0.1',
    userId: 1,
    params: '{}',
    action: '/admin/sys/menu/list',
    method: 'GET',
    status: 200,
    consumeTime: 3,
  },
  {
    createTime: '2021-09-17 02:26:07',
    updateTime: '2021-09-17 02:26:07',
    id: 1453,
    ip: '127.0.0.1',
    userId: 1,
    params: '{"page":1,"limit":20}',
    action: '/admin/sys/user/page',
    method: 'POST',
    status: 200,
    consumeTime: 6,
  },
  {
    createTime: '2021-09-17 02:26:07',
    updateTime: '2021-09-17 02:26:07',
    id: 1452,
    ip: '127.0.0.1',
    userId: 1,
    params: '{}',
    action: '/admin/sys/dept/list',
    method: 'GET',
    status: 200,
    consumeTime: 2,
  },
  {
    createTime: '2021-09-17 02:26:07',
    updateTime: '2021-09-17 02:26:07',
    id: 1451,
    ip: '127.0.0.1',
    userId: 1,
    params: '{}',
    action: '/admin/account/permmenu',
    method: 'GET',
    status: 200,
    consumeTime: 8,
  },
  {
    createTime: '2021-09-17 02:26:07',
    updateTime: '2021-09-17 02:26:07',
    id: 1450,
    ip: '127.0.0.1',
    userId: 1,
    params: '{}',
    action: '/admin/account/info',
    method: 'GET',
    status: 200,
    consumeTime: 2,
  },
  {
    createTime: '2021-09-17 02:25:58',
    updateTime: '2021-09-17 02:25:58',
    id: 1449,
    ip: '127.0.0.1',
    userId: 1,
    params: '{}',
    action: '/admin/account/permmenu',
    method: 'GET',
    status: 200,
    consumeTime: 7,
  },
  {
    createTime: '2021-09-17 02:25:58',
    updateTime: '2021-09-17 02:25:58',
    id: 1448,
    ip: '127.0.0.1',
    userId: 1,
    params: '{}',
    action: '/admin/account/info',
    method: 'GET',
    status: 200,
    consumeTime: 2,
  },
  {
    createTime: '2021-09-17 02:25:58',
    updateTime: '2021-09-17 02:25:58',
    id: 1447,
    ip: '127.0.0.1',
    userId: 1,
    params: '{}',
    action: '/admin/account/permmenu',
    method: 'GET',
    status: 200,
    consumeTime: 8,
  },
  {
    createTime: '2021-09-17 02:25:58',
    updateTime: '2021-09-17 02:25:58',
    id: 1446,
    ip: '127.0.0.1',
    userId: 1,
    params: '{}',
    action: '/admin/account/info',
    method: 'GET',
    status: 200,
    consumeTime: 2,
  },
  {
    createTime: '2021-09-17 02:25:58',
    updateTime: '2021-09-17 02:25:58',
    id: 1445,
    ip: '127.0.0.1',
    userId: 1,
    params: '{}',
    action: '/admin/account/permmenu',
    method: 'GET',
    status: 200,
    consumeTime: 6,
  },
  {
    createTime: '2021-09-17 02:25:58',
    updateTime: '2021-09-17 02:25:58',
    id: 1444,
    ip: '127.0.0.1',
    userId: 1,
    params: '{}',
    action: '/admin/account/info',
    method: 'GET',
    status: 200,
    consumeTime: 2,
  },
  {
    createTime: '2021-09-17 02:25:52',
    updateTime: '2021-09-17 02:25:52',
    id: 1443,
    ip: '127.0.0.1',
    userId: null,
    params: '{}',
    action: '/admin/captcha/img',
    method: 'GET',
    status: 200,
    consumeTime: 4,
  },
  {
    createTime: '2021-09-17 00:56:59',
    updateTime: '2021-09-17 00:56:59',
    id: 1442,
    ip: '127.0.0.1',
    userId: null,
    params: '{}',
    action: '/admin/captcha/img',
    method: 'GET',
    status: 200,
    consumeTime: 3,
  },
  {
    createTime: '2021-09-17 00:56:46',
    updateTime: '2021-09-17 00:56:46',
    id: 1441,
    ip: '127.0.0.1',
    userId: null,
    params: '{}',
    action: '/admin/captcha/img',
    method: 'GET',
    status: 200,
    consumeTime: 4,
  },
  {
    createTime: '2021-09-16 18:05:25',
    updateTime: '2021-09-16 18:05:25',
    id: 1440,
    ip: '127.0.0.1',
    userId: 1,
    params: '{}',
    action: '/admin/account/permmenu',
    method: 'GET',
    status: 200,
    consumeTime: 7,
  },
  {
    createTime: '2021-09-16 18:05:25',
    updateTime: '2021-09-16 18:05:25',
    id: 1439,
    ip: '127.0.0.1',
    userId: 1,
    params: '{}',
    action: '/admin/account/info',
    method: 'GET',
    status: 200,
    consumeTime: 2,
  },
];
