import {
  Modal,
  Table,
  Menu,
  Input,
  Form,
  Card,
  Checkbox,
  Radio,
  Col,
  Row,
  Select,
  DatePicker,
  Statistic,
  Space,
  Divider,
  Switch,
  Popconfirm,
  Badge,
  Avatar,
  Upload,
  Dropdown,
  Typography,
  Affix,
  Tag,
  Transfer,
  PageHeader,
  Descriptions,
  TreeSelect,
} from 'ant-design-vue';

import type { App } from 'vue';
import ProConfigProvider from '@/components/basic/pro-config-provider/index.vue';

import { AButton } from '@/components/basic/button/index';

// import 'ant-design-vue/dist/antd.css';
import 'dayjs/locale/zh-cn';

export function setupAntd(app: App<Element>) {
  app.component('AButton', AButton);
  app.component('ProConfigProvider', ProConfigProvider);

  app
    .use(Form)
    .use(Input)
    .use(Modal)
    .use(Table)
    .use(Menu)
    .use(Card)
    .use(Checkbox)
    .use(Radio)
    .use(Col)
    .use(Row)
    .use(Select)
    .use(DatePicker)
    .use(Statistic)
    .use(Space)
    .use(Divider)
    .use(Switch)
    .use(Popconfirm)
    .use(Badge)
    .use(Avatar)
    .use(Upload)
    .use(Dropdown)
    .use(Typography)
    .use(Affix)
    .use(Tag)
    .use(Transfer)
    .use(PageHeader)
    .use(Descriptions)
    .use(TreeSelect);
}
