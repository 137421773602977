import { Result } from 'ant-design-vue';
import { type RouteRecordRaw } from 'vue-router';
import { notFound, errorRoute } from './staticModules/error';
import { REDIRECT_ROUTE } from './staticModules/besidesLayout';
import outsideLayout from './outsideLayout';
import RouterView from '@/layout/routerView/index.vue';
import { isUrl } from '@/utils/is';
import { uniqueSlash } from '@/utils/urlUtils';
import { constantRouterComponents } from '@/router/asyncModules';
// import common from '@/router/staticModules';
import router, { routes } from '@/router';
import NotFound from '@/views/error/404.vue';
import { type PermissionType } from '@/core/permission/modules/types';

// 需要放在所有路由之后的路由
const endRoutes: RouteRecordRaw[] = [REDIRECT_ROUTE, errorRoute, notFound];

export function filterAsyncRoute(
  routes: API.Menu[],
  parentRoute: API.Menu | null = null,
  lastNamePath: string[] = [],
): RouteRecordRaw[] {
  return (
    routes
      // .filter((item) => item.type !== 2 && item.isShow && item.parentId == (parentRoute?.id ?? 0))
      .filter((item) => item.type !== 2 && item.parentId == (parentRoute?.id ?? 0))
      .map((item) => {
        const { router, viewPath, name, icon, orderNum, keepalive } = item;
        let fullPath = '';
        const pathPrefix = lastNamePath.slice(-1)[0] || '';
        if (isUrl(router)) {
          fullPath = router;
        } else if (router == '#ipfiber') {
          fullPath = window.config.ipfiber_web_url;
        } else {
          fullPath = router.startsWith('/') ? router : '/' + router;
          fullPath = router.startsWith(pathPrefix) ? fullPath : pathPrefix + fullPath;
          fullPath = [...new Set(uniqueSlash(fullPath).split('/'))].join('/');
        }
        let realRoutePath = router;
        if (parentRoute) {
          if (fullPath.startsWith(parentRoute?.router)) {
            realRoutePath = fullPath.split(parentRoute.router)[1];
          } else if (!isUrl(parentRoute.router) && !isUrl(router)) {
            realRoutePath = router;
          }
        }
        realRoutePath = realRoutePath.startsWith('/')
          ? realRoutePath.slice(1)
          : realRoutePath == '#ipfiber'
          ? (realRoutePath = window.config.ipfiber_web_url)
          : realRoutePath;
        const isHideInMenuTabs = item.type === 1 && !item.isShow ? true : false;
        const route: Partial<RouteRecordRaw> = {
          path: realRoutePath,
          name: fullPath,
          meta: {
            orderNum,
            title: name,
            perms: [],
            icon: icon,
            namePath: lastNamePath.concat(fullPath),
            keepAlive: keepalive,
            hideInMenu: isHideInMenuTabs,
            hideInTabs: isHideInMenuTabs,
          },
        };

        if (item.type === 0) {
          // 如果是目录
          const children = filterAsyncRoute(routes, item, lastNamePath.concat(fullPath));
          if (children?.length) {
            route.component = RouterView;
            route.children = children;
            route.redirect = { name: children[0].name };
          } else {
            route.component = (
              <Result
                status="500"
                title={name}
                sub-title="目录类型菜单不是真实页面，请为当前目录添加页面级子菜单或更改当前菜单类型."
              />
            );
          }
          return route;
        } else if (item.type === 1) {
          // 如果是页面
          const Component = constantRouterComponents[viewPath] || NotFound;
          route.component = Component;
          router == '#ipfiber' ? (route.component = RouterView) : '';

          const perms = routes
            .filter((n) => n.parentId === item.id)
            .flatMap((n) => n.perms?.split(','));
          if (route.meta && perms) {
            // 设置当前页面所拥有的权限
            route.meta.perms = perms as PermissionType[];
          }
          return route;
        }
        return undefined;
      })
      .filter((item): item is RouteRecordRaw => !!item)
  );
}

/**
 * 动态生成菜单
 * @param token
 * @returns {Promise<Router>}
 */
export const generatorDynamicRouter = (asyncMenus: API.Menu[]) => {
  try {
    const routeList = filterAsyncRoute(asyncMenus);
    const layout = routes.find((item) => item.name == 'Layout')!;
    // console.log(routeList, '根据后端返回的权限路由生成');
    // 给公共路由添加namePath
    // generatorNamePath(common);
    // const menus = [...common, ...routeList, ...endRoutes];
    const menus = [...routeList, ...endRoutes];
    console.log('后端动态路由', routeList);
    // const menus = [...common, ...endRoutes];
    layout.children = menus;
    const removeRoute = router.addRoute(layout);
    // 获取所有没有包含children的路由，上面addRoute的时候，vue-router已经帮我们拍平了所有路由
    const filterRoutes = router
      .getRoutes()
      .filter((item) => !item.children.length && !outsideLayout.some((n) => n.name === item.name));
    // 清空所有路由
    removeRoute();
    layout.children = [...filterRoutes];
    // 重新添加拍平后的路由
    router.addRoute(layout);
    console.log('所有路由', router.getRoutes());

    return {
      menus: menus,
      routes: layout.children,
    };
  } catch (error) {
    console.error('生成路由时出错', error);
    return {
      menus: [],
      routes: [],
    };
  }
};

/**
 * 主要方便于控制a-menu的open-keys，即控制左侧菜单应当展开哪些菜单
 * @param {RouteRecordRaw[]} routes 需要添加namePath的路由
 * @param {string[]} namePath
 */
export const generatorNamePath = (
  routes: RouteRecordRaw[],
  namePath?: string[],
  parent?: RouteRecordRaw,
) => {
  routes.forEach((item) => {
    if (item.children?.length) {
      if (item.meta && typeof item.name === 'string') {
        item.meta.namePath = Array.isArray(namePath) ? namePath.concat(item.name) : [item.name];
        item.meta.fullPath = parent?.meta?.fullPath
          ? [parent.meta.fullPath, item.path].join('/')
          : item.path;
        item.meta.fullPath = uniqueSlash(item.meta.fullPath);
        generatorNamePath(item.children, item.meta.namePath, item);
      }
    } else {
      if (item.meta && typeof item.name === 'string') {
        item.meta.namePath = Array.isArray(namePath) ? namePath.concat(item.name) : [item.name];
        item.meta.fullPath = parent?.meta?.fullPath
          ? [parent.meta.fullPath, item.path].join('/')
          : item.path;
        item.meta.fullPath = uniqueSlash(item.meta.fullPath);
      }
    }
  });
};
