export default {
  i18n: {
    SYSTEM_ERROR: 'SYSTEM_ERROR',
    UNMARSHAL_CACHE: 'UNMARSHAL_CACHE',
    RESOURCE_NOT_FOUND: 'RESOURCE_NOT_FOUND',
    TAGDATA_NOT_FOUND: 'TAGDATA_NOT_FOUND',
    ENV_NOT_FOUND: 'ENV_NOT_FOUND',
    NODE_EXISTS: 'NODE_EXISTS',
    NODE_NAME_EXISTS: 'NODE_NAME_EXISTS',
    PARAMETERS_EMPTY: 'PARAMETERS_EMPTY',
    UNSUPPORTED_OPERATION: 'UNSUPPORTED_OPERATION',
    AUTH_DENIED: 'AUTH_DENIED',
    USER_NOT_RFOUND: 'USER_NOT_FOUND',
    TENANT_NOT_FOUND: 'TENANT_NOT_FOUND',
    USERNAME_OR_PASSWORD_INVALID: 'USERNAME_OR_PASSWORD_INVALID',
    USER_EXISTED: 'USER_EXISTED',
    USER_INVALID: 'USER_INVALID',
    TENANT_EXISTED: 'TENANT_EXISTED',
    SIGNATURE_FAILD: 'SIGNATURE_FAILD',
    PASSWORD_UNMATCH: 'PASSWORD_UNMATCH',
    UNKNOWN_ERROR: 'UNKNOWN_ERROR',
    MODIFY_PASSWORD: 'MODIFY_PASSWORD',
    CONTENT_MISSING: 'CONTENT_MISSING',
    WIFI_DATA_EXISTS: 'WIFI_DATA_EXISTS',
    GENERATE_TOKEN: 'GENERATE_TOKEN',
    RULES_DATA: 'RULES_DATA',
    LOGIN: 'LOGIN',
    USER_NOTAUTH: 'USER_NOTAUTH',
  },
  zh_CN: {
    SYSTEM_ERROR: '系统错误, 请稍后重试',
    UNMARSHAL_CACHE: '缓存数据解析失败, 请重试',
    RESOURCE_NOT_FOUND: '资源未找到, 请再次确认',
    TAGDATA_NOT_FOUND: '标签数据未找到, 请再次确认',
    ENV_NOT_FOUND: '代理终端未找到, 请稍后重试',
    NODE_EXISTS: '当前节点已存在, 请检查!',
    NODE_NAME_EXISTS: '当前节点名称已存在, 请检查!',
    PARAMETERS_EMPTY: '参数不能为空, 请检查!',
    UNSUPPORTED_OPERATION: '不支持的操作, 请检查!',
    USER_NOT_RFOUND: '用户未找到, 请再次确认',
    TENANT_NOT_FOUND: '租户未找到, 请再次确认',
    USERNAME_OR_PASSWORD_INVALID: '用户名或密码错误, 请检查',
    USER_EXISTED: '当前用户名已存在',
    USER_INVALID: '当前用户未启用, 请联系管理员',
    TENANT_EXISTED: '当前租户已存在',
    SIGNATURE_FAILD: '签名错误, 请检查',
    UNKNOWN_ERROR: '未知错误, 请重试',
    TOKEN_EXPIRED: '用户验证已过期, 请重新登录!',
    TOKEN_FORMAT_ERROR: '用户Access Token格式有误, 请检查!',
    AUTH_DENIED: '当前用户没有此操作权限!',
    PASSWORD_UNMATCH: '两次输入的密码不一致, 请检查!',
    MODIFY_PASSWORD: '用户修改租户密码错误，租户ID不能为空',
    CONTENT_MISSING: '内容不能为空, 请检查!',
    WIFI_DATA_EXISTS: '当前WIFI数据已存在, 请检查!',
    GENERATE_TOKEN: '生成Token失败, 请重试!',
    RULES_DATA: '转换整数错误',
    LOGIN: '登录失败，系统错误，请稍后重试',
    USER_NOTAUTH: '用户未认证',
  },
  en_US: {
    SYSTEM_ERROR: 'System error, please try again later!',
    UNMARSHAL_CACHE: 'Unmarshal cache data failed, please try again!',
    RESOURCE_NOT_FOUND: 'Resource not found, please try again!',
    TAGDATA_NOT_FOUND: 'Tag data not found, please try again!',
    ENV_NOT_FOUND: 'Proxy agent not found, please try again later!',
    NODE_EXISTS: 'Current node is existed, please check!',
    NODE_NAME_EXISTS: 'Current node name is existed, please check!',
    PARAMETERS_EMPTY: 'Parameters can not be empty, please check!',
    UNSUPPORTED_OPERATION: 'Unsupported operation, please check!',
    USER_NOT_RFOUND: 'User not found.',
    TENANT_NOT_FOUND: 'Tenant not found.',
    USERNAME_OR_PASSWORD_INVALID: 'Username or password is invalid, please check again.',
    USER_EXISTED: 'Current user existed, please try another one.',
    USER_INVALID: 'Current user is disabled, please contact administrator.',
    TENANT_EXISTED: 'Current tenant existed, please try another one.',
    SIGNATURE_FAILD: 'Signature is failed, please try again!',
    UNKNOWN_ERROR: 'Unknown error, please try again!',
    TOKEN_EXPIRED: 'User credential expired, please login again',
    TOKEN_FORMAT_ERROR: 'Wrong access token format! Please check.',
    AUTH_DENIED: 'You have no permission to access!',
    PASSWORD_UNMATCH: 'The two passwords entered are inconsistent, please check!',
    MODIFY_PASSWORD: 'User modify tenant password error, tenant ID can not be empty',
    CONTENT_MISSING: 'Content can not be empty, please check!',
    WIFI_DATA_EXISTS: 'Current WIFI data existed, please check!',
    GENERATE_TOKEN: 'Generate token failed, please try again!',
    RULES_DATA: 'Convert integer error',
    LOGIN: 'Login failed, system error, please try again later',
    USER_NOTAUTH: 'User not authenticated',
  },
};
